<template>
  <div>
    <transition name="fade">
      <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
                 :md-active.sync="isActivated">
        <md-dialog-content>
          <md-card-area md-inset>
            <md-card-header>
              <div class="md-title">Événements</div>
            </md-card-header>

            <md-card-content>
              <md-list v-if="events.length">
                <md-list-item v-for="event in events" :key="event.id">
                  <md-field>
                    <label>{{ event.name }}</label>
                    <md-select md-dense v-model="event.pivot.status">
                      <md-option :value="eventStatus.paid">Payé</md-option>
                      <md-option :value="eventStatus.unpaid">Impayé</md-option>
                      <md-option :value="eventStatus.cancelled">Annulé</md-option>
                    </md-select>
                  </md-field>
                </md-list-item>
              </md-list>
              <span v-else>Inscrit à aucun événement</span>
            </md-card-content>

          </md-card-area>

          <md-card-actions md-alignment="space-between">
            <div>
              <md-button class="md-accent" @click="close">Annuler</md-button>
            </div>
            <div>
              <md-button class="md-primary" @click="save">Valider</md-button>
            </div>
          </md-card-actions>
        </md-dialog-content>
      </md-dialog>
    </transition>
    <md-button class="md-raised md-primary" @click="isActivated = true">
      <md-icon>event</md-icon>
      événements
    </md-button>
  </div>
</template>

<script>

import EventService from '@/components/event/EventService';
import eventStatus from '@/components/event/event-status';

export default {
  name: 'EventList',
  data: () => ({
    isActivated: false,
    events: [],
    eventStatus,
  }),
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    statuses() {
      return this.events.reduce((acc, event) => {
        const { status } = event.pivot;
        return status ? { ...acc, [event.id]: { status } } : acc;
      }, {});
    },
  },
  methods: {
    save() {
      this.$emit('save', this.statuses);
      this.isActivated = false;
    },
    close() {
      this.isActivated = false;
      this.$emit('closed');
    },
  },
  async mounted() {
    this.events = await EventService.fromUser(this.userId);
  },
};

</script>
