<template>
  <div>
    <transition name="fade">
      <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
                 :md-active.sync="isActivated">
        <md-dialog-content>
          <md-card-area md-inset>
            <md-card-header>
              <div class="md-title">Jeux</div>
            </md-card-header>

            <md-card-content>
              <md-list>
                <md-list-item v-for="game in games" :key="game.id">
                  <md-field>
                    <label>{{ game.name }}</label>
                    <md-input v-model="game.players[0].player_id"/>
                  </md-field>
                </md-list-item>
              </md-list>
            </md-card-content>

          </md-card-area>

          <md-card-actions md-alignment="space-between">
            <div>
              <md-button class="md-accent" @click="close">Annuler</md-button>
            </div>
            <div>
              <md-button class="md-primary" @click="save">Valider</md-button>
            </div>
          </md-card-actions>
        </md-dialog-content>
      </md-dialog>
    </transition>
    <md-button class="md-raised md-primary" @click="isActivated = true">
      <md-icon>verified</md-icon>
      ID TCG
    </md-button>
  </div>
</template>

<script>

import GameService from '@/components/game/GameService';

export default {
  name: 'PlayerList',
  data: () => ({
    isActivated: false,
    games: [],
  }),
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    playerIds() {
      return this.games.reduce((acc, game) => {
        // eslint-disable-next-line camelcase
        const { player_id } = game.players[0];
        // eslint-disable-next-line camelcase
        return player_id ? { ...acc, [game.id]: { player_id } } : acc;
      }, {});
    },
  },
  methods: {
    save() {
      this.$emit('save', this.playerIds);
      this.isActivated = false;
    },
    close() {
      this.isActivated = false;
      this.$emit('closed');
    },
  },
  async mounted() {
    this.games = await GameService.indexWithPlayer(this.userId);
  },
};

</script>
