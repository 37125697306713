<template>
  <div>
    <div class="md-layout md-alignment-center-space-between">
      <div class="heading md-layout md-alignment-center-space-between
            md-size-20 md-medium-20 md-small-100">
        <h1 class="md-layout-item md-size-10 md-medium-20 md-small-100">{{ title }}</h1>
        <md-button class="filter-button no-margin md-raised md-primary"
                   @click="showFilters = !showFilters">
          Filtres
        </md-button>
      </div>
      <div :class="{hideFilters:showFilters}" class="md-layout md-gutter md-layout-item md-alignment-center
        md-size-45 md-medium-size-60 md-small-size-100">
        <div class="md-layout-item">
          <md-field>
            <label>Nom</label>
            <md-input @keyup.enter="search" v-model="filter.name"/>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label>Email</label>
            <md-input @keyup.enter="search" v-model="filter.email"/>
          </md-field>
        </div>

        <md-button @click="search" class="md-icon-button md-primary">
          <md-icon>search</md-icon>
        </md-button>

      </div>
    </div>

    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Email</span></div>
        <div class="md-layout-item md-layout-item md-size-10 md-small-20 md-xsmall-size-15"></div>
      </div>
    </md-list-item>

    <md-list>
      <user-item v-for="user of objects" :key="user.id" :object="user" @deleted="removeObject"/>
    </md-list>

    <pagination v-model="page" :records="total" :per-page="20" @paginate="getNext"
                :options="options"/>

    <user-form @submit="saveObject"/>
  </div>
</template>

<script>

import GameService from '@/components/game/GameService';
import UserService from '@/components/user/UserService';
import UserItem from '@/components/user/UserItem.vue';
import UserForm from '@/components/user/UserForm.vue';
import pageMixin from '@/mixins/page-mixin';
import paginationMixin from '@/mixins/pagination-mixin';

export default {
  name: 'UsersPage',
  data: () => ({
    title: 'Utilisateurs',
    service: UserService,
  }),
  components: {
    UserItem,
    UserForm,
  },
  mixins: [pageMixin, paginationMixin],
  async mounted() {
    try {
      await this.getFirst();
      await this.$store.dispatch('addGames', await GameService.index());
    } catch (err) {
      this.handleError(err);
    }
  },
};
</script>
