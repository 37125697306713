<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <validator v-slot="{ handleSubmit }">
          <form novalidate class="md-layout" @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <span class="md-headline">{{ title }}</span>
              <div>
                <div v-if="object.id" class="md-layout md-gutter md-alignment-center-space-around">
                  <player-list :userId="object.id" @save="addGames"
                               class="md-layout-item md-size-40"
                               @closed="disabled = false"
                               @click.native="preventAction"/>

                  <event-list :userId="object.id" @save="addEvents"
                              class="md-layout-item md-size-40"
                              @closed="disabled = false"
                              @click.native="preventAction"/>
                </div>
                <rule name="nom" rules="required" v-slot="{errors}">
                  <md-field>
                    <label>Nom</label>
                    <md-input name="name" v-model="object.name"/>
                    <span class="input-error">{{ errors[0] }}</span>
                  </md-field>
                </rule>
                <rule name="e-mail" rules="required|email" v-slot="{errors}">
                  <md-field>
                    <label>Adresse mail</label>
                    <md-input v-model="object.email"/>
                    <span class="input-error">{{ errors[0] }}</span>
                  </md-field>
                </rule>

                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item md-small-size-100">
                    <rule name="mot de passe" :rules="{required:!object.id}" v-slot="{errors}">
                      <md-field>
                        <label>Mot de passe</label>
                        <md-input type="password" v-model="object.password"/>
                        <span class="input-error">{{ errors[0] }}</span>
                      </md-field>
                    </rule>
                  </div>

                  <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="object.is_admin">Admin</md-switch>
                  </div>
                </div>

                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label>Pseudo</label>
                      <md-input v-model="object.nickname"/>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label>Téléphone</label>
                      <md-input v-model="object.phone"/>
                    </md-field>
                  </div>
                </div>

                <md-field>
                  <label>Adresse</label>
                  <md-input v-model="object.address"/>
                </md-field>
              </div>
              <div class="buttons-panel">
                <md-button :disabled="disabled" class="md-raised md-accent" @click="close">Annuler
                </md-button>
                <md-button :disabled="disabled" type="submit" class="md-raised md-primary">Valider
                </md-button>
              </div>
            </div>
          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>

    <div class="fab-space"/>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import { mapGetters } from 'vuex';
import UserService from '@/components/user/UserService';
import PlayerList from '@/components/user/player/PlayerList.vue';
import EventList from '@/components/event/EventList.vue';
import notificationMixin from '@/mixins/notification-mixin';
import formMixin from '@/mixins/form-mixin';

extend('email', {
  ...email,
  message: messages.email,
});

extend('required', {
  ...required,
  message: messages.required,
});

export default {
  name: 'UserForm',
  data: () => ({
    model: 'Utilisateur',
    service: UserService,
    disabled: false,

  }),
  mixins: [notificationMixin, formMixin],
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
    PlayerList,
    EventList,
  },
  computed: {
    ...mapGetters(['games', 'memberships']),
  },
  methods: {
    addGames(games) {
      this.object.games = games;
      this.disabled = false;
    },
    addEvents(events) {
      this.object.events = events;
      this.disabled = false;
    },
    preventAction() {
      this.disabled = true;
    },
  },
};
</script>
