var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('md-dialog',{attrs:{"md-close-on-esc":false,"md-click-outside-to-close":false,"md-active":_vm.isModalVisible},on:{"update:mdActive":function($event){_vm.isModalVisible=$event},"update:md-active":function($event){_vm.isModalVisible=$event}}},[_c('md-dialog-content',[_c('validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"md-layout",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',[_c('span',{staticClass:"md-headline"},[_vm._v(_vm._s(_vm.title))]),_c('div',[(_vm.object.id)?_c('div',{staticClass:"md-layout md-gutter md-alignment-center-space-around"},[_c('player-list',{staticClass:"md-layout-item md-size-40",attrs:{"userId":_vm.object.id},on:{"save":_vm.addGames,"closed":function($event){_vm.disabled = false}},nativeOn:{"click":function($event){return _vm.preventAction($event)}}}),_c('event-list',{staticClass:"md-layout-item md-size-40",attrs:{"userId":_vm.object.id},on:{"save":_vm.addEvents,"closed":function($event){_vm.disabled = false}},nativeOn:{"click":function($event){return _vm.preventAction($event)}}})],1):_vm._e(),_c('rule',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Nom")]),_c('md-input',{attrs:{"name":"name"},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('rule',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Adresse mail")]),_c('md-input',{model:{value:(_vm.object.email),callback:function ($$v) {_vm.$set(_vm.object, "email", $$v)},expression:"object.email"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"md-layout md-gutter md-alignment-center"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('rule',{attrs:{"name":"mot de passe","rules":{required:!_vm.object.id}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Mot de passe")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.object.password),callback:function ($$v) {_vm.$set(_vm.object, "password", $$v)},expression:"object.password"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-switch',{model:{value:(_vm.object.is_admin),callback:function ($$v) {_vm.$set(_vm.object, "is_admin", $$v)},expression:"object.is_admin"}},[_vm._v("Admin")])],1)]),_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',[_c('label',[_vm._v("Pseudo")]),_c('md-input',{model:{value:(_vm.object.nickname),callback:function ($$v) {_vm.$set(_vm.object, "nickname", $$v)},expression:"object.nickname"}})],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',[_c('label',[_vm._v("Téléphone")]),_c('md-input',{model:{value:(_vm.object.phone),callback:function ($$v) {_vm.$set(_vm.object, "phone", $$v)},expression:"object.phone"}})],1)],1)]),_c('md-field',[_c('label',[_vm._v("Adresse")]),_c('md-input',{model:{value:(_vm.object.address),callback:function ($$v) {_vm.$set(_vm.object, "address", $$v)},expression:"object.address"}})],1)],1),_c('div',{staticClass:"buttons-panel"},[_c('md-button',{staticClass:"md-raised md-accent",attrs:{"disabled":_vm.disabled},on:{"click":_vm.close}},[_vm._v("Annuler ")]),_c('md-button',{staticClass:"md-raised md-primary",attrs:{"disabled":_vm.disabled,"type":"submit"}},[_vm._v("Valider ")])],1)])])]}}])})],1)],1),_c('div',{staticClass:"fab-space"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }